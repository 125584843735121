import React from 'react'
import Meta from '../components/Meta'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Header from '../components/Header'
import Footer from '../components/Footer'
import RichText from '../components/RichText'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBullseye,
    faCloud,
    faBox
} from '@fortawesome/free-solid-svg-icons'
import { format } from 'react-string-format'


class IndexRoute extends React.Component {
    render() {
        const routeData = this.props.data
        const metaDataElements = routeData.kontentItemSiteMetadata.elements
        const title = metaDataElements.title.value

        const homepageElements = routeData.kontentItemHomepage.elements

        const aboutMeText = homepageElements.about_me

        const serviceItems = this.props.data.kontentItemServicesOverviewPage.elements.services.value
        const servicesUrl = this.props.data.kontentItemServicesOverviewPage.elements.slug.value
        const items = []
        serviceItems.forEach(serviceItem => {
            items.push({ hash: "#" + serviceItem.elements.slug.value, title: serviceItem.elements.title.value })
        })

        return (
            <Layout>
                <div>
                    <Meta title={title} postfix={title} description={title} />
                    <Header isHomePage />
                    <Hero isHomePage title="Vyřešíme zateplení vašeho domu!" />
                    <section className="about-me">
                        <div className="container">
                            <div className="text-and-image text-and-image--center row">
                                <div className="text-and-image__text col-lg-8">
                                    <RichText data={aboutMeText} />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section container">
                        <div className="row">
                            {serviceItems &&
                                serviceItems.map((serviceItem, index) => (
                                    <div className="col-xxs-24 col-sm-4">
                                        <div className="service">
                                            <FontAwesomeIcon icon={serviceItem.elements.icon.value == "cloud" ? faCloud : serviceItem.elements.icon.value == "target" ? faBullseye : faBox} size="1x" />
                                            <h3 className="service__title">{serviceItem.elements.title.value}</h3>
                                            <p className="service__description">{serviceItem.elements.short_description.value}</p>
                                            <a href={servicesUrl + `#${serviceItem.elements.slug.value}`} className="button" data-tracking-label={format('Service_Button_{0}', serviceItem.elements.title.value)}>Na detaily</a>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </section>
                    <Footer />
                </div>
            </Layout>
        )
    }
}

export default IndexRoute

export const pageQuery = graphql`
  query IndexQuery {
    kontentItemSiteMetadata(system: {codename: {eq: "site_metadata"}}) {
      elements {
        title {
          value
        }
      }
    }
    kontentItemHomepage(system: {codename: {eq: "uvodni_stranka"}}) {
      elements {
        about_me {
          value
          links {
            link_id
            url_slug
            type
          }
          images {
            image_id
            description
            url
            fluid(maxWidth: 700) {
              ...KontentAssetFluid
            }
          }
          modular_content {
            ... on kontent_item_component_images_wrapper {
              id
              elements {
                description {
                  value
                }
                photos {
                  value {
                    fluid(maxWidth: 350) {
                      aspectRatio
                      ...KontentAssetFluid
                    }
                    fixed(height: 700) {
                      aspectRatio
                      src
                      ...KontentAssetFixed
                    }
                    description
                    url
                  }
                }
              }
              system {
                codename
                type
              }
            }
          }           
        }
      } 
    }  
  kontentItemServicesOverviewPage(system: { codename: { eq: "nabidka" } }) {
    id
    system {
      id
    }
    elements {
      slug {
        value
      }
      meta_description {
        value
      }
      title {
        value
      }
      services {
        value {
          ... on kontent_item_service_item {
            id
            elements {
              title {
                value
              }
              slug {
                value
              }
              icon {
                value
              }
              short_description {
                value
              }
            }
          }
        }
      }
    }
  }
 }
`
